<template>

  <div class="wrapper">

    <section style="background-color: var(--darkBackground); color: var(--lightest-gray);" class="register-form-wrapper p-2"
      v-show="action_login">
      <div>
        <h3 class=" text-center mt-1 mb-3"> <span style="color: white;" class="">Login</span> </h3>
        <div class="text-start mb-0 mx-3">
          <p style="display: block; color: var(--lightest-gray); ">Enter your phone number and Password below to Login
            to your existing account. Otherwise click on Register with the same details to create a new account.</p>
        </div>
        <div class="form-wrapper mb-3">
          <form>
            <div class=" mb-0">
              <span class="mx-1 mb-2">Phone Number</span>
              <input autocomplete="off"
                style="border: 0px black solid;border-radius: 5px;background-color: #23313d;color: white;width: 100%;height: 50px;text-align: start;"
                type="tel" class="form-control input-field" id="phoneInput" placeholder="e.g. 0712 345678 "
                aria-label="Enter Phone number" name="mobile" v-model="msisdn">
              <span class="mx-1">Enter your phone number</span>
            </div>
            <small v-if="phoneInvalid" class="text-danger text-center">Invalid phone number</small>
            <div class="mb-3"></div>
            <div class="mb-0">
              <div class="mb-0">
                <div class="row justify-content-between mx-1 my-2"><span>Password</span><a @click="setGetResetCode">
                    <span style="color: var(--yellow)">Forgot Your Password?</span></a></div>
                <input autocomplete="off" :type="type" class="form-control input-field" id="inputPass"
                  style="border-radius: 5px;background-color:#23313d;color: white; width: 100%;height: 50px; text-align: start;"
                   placeholder="Enter Password" aria-label="Enter Password" v-model="password">
                <span class="mx-1">Enter your password</span>
              </div>
            </div>
            <small class="text-dark mb-3 d-none">Enter the 4 digit code sent to your phone</small>
            <input type="hidden" name="utm_source" value="">
            <input type="hidden" name="utm_medium" value="">
            <input type="hidden" name="utm_campaign" value="">
            <input type="hidden" name="referrer" value="">
          </form>
        </div>

        <div class="disclaimer text-center d-none">
          By continuing for FalconBet, you confirm that you are 18 years old or over and agree with the <a
            class="text-dark">Terms, conditions and policies</a> of FalconBet
        </div>

        <div v-if="!loading" style="" class="button-wrapper text-center mb-3">
          <a @click="login" class=" py-2 form-control"
            style="background-color: var(--yellow); color: #12121e;">Login</a>
        </div>
        <div v-else-if="loading" style="" class="button-wrapper text-center mb-3">
          <a class="py-2 form-control" style="background-color: var(--yellow); color: #12121e;">Loading...</a>
        </div>

        <div class="login-button-wrapper text-center p-3 mb-3 ">
          <div class="text-center text-dark ">
            <!-- Did you forget your Password? <a @click="setGetResetCode"> <span
                style="text-decoration: underline;">Reset</span></a> -->
            <!-- <br>
            <br> -->
            <div style="color: var(--lightest-gray);" class="text-center mb-3">
              <!-- Don't have an account? <a @click="setSignup"> <span style=""> Register here</span></a> -->
              <router-link style="color: var(--lightest-gray);" to="/join"> Don't have an account? <span style=""> Register here</span></router-link>
              <br>
              
            </div>
          </div>
        </div>
      </div>
    </section>

    <section style="background-color: #23313d;color: white;" class="register-form-wrapper px-2 py-4"
      v-show="action_signup">
      <h3 class=" text-center mt-1 mb-1 py-3">Register </h3>
      <div class="mx-2">
        Enter your phone number and password below to Login to your existing account. Otherwise click on Register with
        the same details to create a new account.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <span>Phone Number</span>
            <input
              style="border: 0px black solid;border-radius: 5px;background-color: var(--darkBackground);color: white;width: 100%;height: 40px;text-align: start;"
              type="tel" class="form-control input-field" placeholder="e.g. 0712 345678" aria-label="Enter Phone Number"
               id="msisdn" v-model="msisdn">
            <small>Enter your Phone Number</small>
          </div>
          <div class="mb-3">
            <div class="">
              <span>Password</span>
              <input :type="type" class="form-control input-field" id="inputPass"
                style="border-radius: 5px;background-color: var(--darkBackground);color: white;text-align: start;" name="password"
                aria-label="Enter Password" v-model="password">
              <small>Enter your Password</small>
            </div>
          </div>
          <div class="mb-3">
            <div class="">
              <span>Confirm Password</span>
              <input :type="type" class="form-control input-field" id="signupPassword1"
                style="border-radius: 5px;background-color: var(--darkBackground);color: white;text-align: start;" name="password_2"
                aria-label="Confirm Password" v-model="password1">
              <small>Confirm Password</small>

            </div>
          </div>
        </form>
      </div>
      <div class="" style="">
        <div class="row justify-content-start mx-3 align-items-start">
          <div class="mt-2 "><input type="checkbox"  v-model="termsCheckBox" id="termsCheckBox"
              style="accent-color: var(--yellow); background-color: red; height: 20px; width: 20px;"></div>
          <div class="col">
            <p class=""> By clicking Register you confirm to have read in detail, understood and agreed to the Terms
              and Conditions, the Privacy policy and also that you are over 18 years of age. </p>

          </div>
        </div>
        <div class="div justify-content-start" style="text-align: start">
          <p v-if="showTermsCheckBoxError" style="color: red;"> Please Accept our terms and Conditions before
            registration</p>
        </div>
      </div>

      <div style="" class="disclaimer text-start">
        By registering for FalconBet, you confirm that you are 18 years old or over and agree with the <a
          class="">Terms, conditions and policies</a> of FalconBet
      </div>

      <div style="color:#12121e;" class=" text-center mb-3 mx-2">
        <a style="background-color: var(--yellow); color:#12121e;" @click="signup" class=" py-2 form-control">Register</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-dark mb-3">
          <!-- Already have an account? <a @click="setLogin"> <span style="text-decoration: underline;"> Login</span></a> -->
          <br>
        </div>
      </div>

    </section>

    <section style="background-color: var(--lightest-gray);color: black;" class="register-form-wrapper p-4"
      v-show="action_verify_password">
      <h3 class="text-dark text-center mt-1 mb-3">Registration <span class="text-dark">Code</span> </h3>
      <div class="text-center text-dark mb-3">
        Check your phone for an SMS verification code
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-0">
            <input
              style="border: 1px black solid;border-radius: 19px;background-color: white;color: black;width: 100%;height: 40px;text-align: center;"
              type="tel" class="form-control input-field" placeholder="Enter code" id="code" aria-label="Enter Code"
              v-model="code">
          </div>
          <small class="text-dark mb-3">Enter the 4 digit code sent to your phone</small>

        </form>
      </div>

      <div class="disclaimer text-center text-dark">
        By activating yout FalconBet account, you confirm that you are 18 years old or over and agree with the <a
          class="text-dark">Terms, conditions and policies</a> of FalconBet
      </div>

      <div class="button-wrapper text-center mb-3">
        <a @click="verifyAccount" class="join-button py-2 form-control">ACTIVATE ACCOUNT</a>
      </div>

      <div class="login-button-wrapper text-center p-3 mb-3 ">
        <div class="text-center text-dark mb-3">
          Didn’t get an SMS code?<br>
          Let’s try again in<br>
          (15 seconds)
        </div>
        <a href="#n" class="login-button py-2 form-control">RESEND</a>
      </div>

    </section>

    <section style="background-color: var(--darkBackground);color: white;" class="register-form-wrapper p-2"
      v-show="action_reset_password">
      <h3 class=" text-center mt-1 mb-3"> <span class="">Phone Number</span> </h3>
      <div class="text-center text-dark mb-3">

      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class="mb-3">
            <span class=" mx-1">Phone Number</span>
            <input
              style="border-radius: 5px;background-color: #23313d;color: white;width: 100%;height: 40px;text-align: start;"
              type="tel" class="form-control input-field" id="msisdn1" placeholder="e.g. 0712 345678"
              aria-label="Amount (to the nearest dollar)" v-model="msisdn">
          </div>

        </form>
      </div>
      <div v-if="!loading" class="button-wrapper text-center mb-3">
        <a @click="getResetCode" style="background-color: var(--yellow); color: var(--darkBackground); "
          class=" py-2 form-control">Send Code</a>
      </div>
      <div v-else-if="loading" class="button-wrapper text-center mb-3">
        <a class=" py-2 form-control"
          style="background-color: var(--yellow); color: var(--darkBackground); ">Loading...</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center mb-3">
          Already have an account? <a @click="setLogin"> <span style="">Login</span></a><br>
        </div>
      </div>

    </section>

    <section style="background-color: #23313d;color: white;" class="register-form-wrapper p-2"
      v-show="action_change_password">
      <h3 class=" text-center mt-1 mb-3">Reset your Password </h3>
      <div class="text-start mb-3 mx-3">
        <!-- Password reset code has been send to your mobile number. Enter the code you have received in your phone and your
        new password. -->

        Confirm your new password and enter the verification code that was sent to your phone to complete password
        reset. If you did not receive a code on SMS, dial ***** to get your verification code.
      </div>
      <div class="form-wrapper mb-3">
        <form>
          <div class=" mb-3">
            <span class="ml-1">Phone Number</span>
            <input
              style="border: 0px black solid;border-radius: 5px;background-color: #12121e; color: white;width: 100%;height: 40px;text-align: start;"
              type="tel" class="form-control input-field" id="phoneInput" placeholder="e.g. 0712 345678"
              aria-label="Enter Phone number" name="mobile" v-model="msisdn">
            <small class="ml-1">Enter your phone number</small>
          </div>

          <div class="mb-3">
            <div class="">
              <span class="ml-1">Password</span>
              <input :type="type" class="form-control input-field" id="signupPassword3"
                style="border-radius: 5px;background-color: #12121e;width: 100%;height: 40px;color: white;text-align: start;" name="password"
                aria-label="New Password" v-model="password">
              <small class="ml-1">Enter your Password</small>
            </div>
          </div>
          <div class="mb-3">
            <div class="">
              <span class="ml-1">Confirm Password</span>
              <input :type="type" class="form-control input-field" id="signupPassword1"
                style="border-radius: 5px;background-color: #12121e;width: 100%;height: 40px;color: white;text-align: start;" name="password_2"
                aria-label="Confirm New Password" v-model="password1">
              <small class="ml-1">Confirm Password</small>
            </div>
          </div>
          <div class="mb-3">
            <div class="row justify-content-between mx-1">
              <span>Verification Code</span>
              <a @click="getResetCode"><span style="color: var(--yellow);">Resend Code</span></a>
            </div>
            <input
              style="border: 0px black solid;border-radius: 5px;background-color: #12121e;color: white;width: 100%;height: 40px;text-align: start;"
              type="tel" class="form-control input-field" id="code1" aria-label="Enter Code" v-model="code">
            <small>Enter the verification code that was sent to your phone</small>
          </div>

        </form>
      </div>

      <div class="disclaimer text-center d-none">
        By cont for FalconBet, you confirm that you are 18 years old or over and agree with the <a
          class="text-dark">Terms, conditions and policies</a> of FalconBet
      </div>

      <div v-if="!loading" class="button-wrapper text-center mb-3">
        <a @click="changePassword" style="color: var(--darkBackground);" class=" py-2 form-control">Verify</a>
      </div>
      <div v-else-if="loading" class="button-wrapper text-center mb-3">
        <a style="color: var(--darkBackground);" class=" py-2 form-control">Loading...</a>
      </div>
      <div class="login-button-wrapper text-center p-3 mb-1 ">
        <div class="text-center text-dark mb-3">
          <!-- Already have an account? <a @click="setLogin"> <span style="text-decoration: underline;">Login</span></a><br> -->
        </div>
      </div>

    </section>


  </div>



</template>

<style scoped>
.input-signup,
.input-login {
  border: 1px solid black;
  border-radius: 19px;
  height: 3em;
  width: 100%;
  color: var(--grey);
  text-align: center;
  background-color: var(--grey);
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.input-field:focus {
  outline: none;
  box-shadow: 0 0 5px 2px var(--yellow);
}
input[type=checkbox] {
  position: relative;
  cursor: pointer;
}

input[type=checkbox]:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  /* border: 1px solid var(--darkBackground); */
  /* border-radius: 3px; */
  padding: 1px;
  background-color: var(--darkBackground);
}

input[type=checkbox]:checked:before {
  background-color: var(--yellow);
}

input[type=checkbox]:checked:after {
  content: "";
  display: block;
  width: 8px;
  height: 13px;
  border: solid var(--darkBackground);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(40deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
</style>

<script>
import axios from "@/services/identity";
import { mapState } from 'vuex';
export default {
  name: 'Login',
  components: {
  },
  comments: {

  },
  data: function () {

    return {
      msisdn: '',
      password: '',
      password1: '',
      error: [],
      warning: [],
      success: [],
      loading: '',
      action_login: true,
      action_signup: false,
      action_verify_password: false,
      action_reset_password: false,
      action_change_password: false,
      code: '',
      placeBet: 0,
      type: 'password',
      type2: 'password',
      type3: 'password',
      btnText: '/img/icons/eye.svg',
      btnText2: '/img/other/eye.svg',
      termsCheckBox: false,
      btnText3: '/img/other/eye.svg',
    }
  },
  computed: {
    phoneInvalid: function () {
      if (this.msisdn.length < 10 && this.msisdn.length > 0) {
        return true;
      } else {
        return false;
      }

    },
    ...mapState(['referralCode']),
  },
  methods: {
    login: function () {
      this.reset();
      this.removeAuth();

      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top'
        });
        return;
      }

      if (this.password.length < 4) {
        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top'
        });
        return;
      }

      this.loading = 'loading';
      this.spinner = true;

      var vm = this;

      var path = "/login";

      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");


      console.log("Login method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Payload:", {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      });
      axios.post(path, JSON.stringify({
        msisdn: parseInt(this.msisdn),
        password: this.password,
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer
      }))
        .then(res => {
          vm.loading = '';
          vm.spinner = false;

          var profile = res.data;
          var status = res.status;

          if (parseInt(status) === 201 || parseInt(status) === 204) {
            vm.setVerifyAccount();
            return;
          }

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          this.$toast.open({
            message: 'Login successful!',
            type: 'success',
            position: 'top'
          });

          localStorage.setItem('msisdn', parseInt(this.msisdn));
          localStorage.setItem('password', this.password);

          vm.EventBus.$emit('init:mqtt');

          setTimeout(() => {
            window.location.href = "/";
          }, 2000);
        })
        .catch(err => {
          vm.loading = '';
          vm.spinner = false;

          if (err.response) {
            this.$toast.open({
              message: 'Login failed!',
              type: 'error',
              position: 'top'
            });
          }
          else if (err.request) {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err.request));
          }
          else {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top'
            });
            console.log(JSON.stringify(err));
          }
        })
    },
    handleBlur: function () {
      if (this.msisdn.length < 9 && this.msisdn.length > 0) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        document.getElementById('phoneInput').classList.add("is-invalid")

      } else {
        document.getElementById('phoneInput').classList.remove("is-invalid")
      }
    },

    getBrowserInfo() {
      return navigator.userAgent || 'unknown';
    },

    getDeviceInfo() {
      return `${navigator.platform} ${navigator.userAgent}`;
    },

    getEngineInfo() {
      return 'JavaScript';
    },

    getVersionInfo() {
      return navigator.appVersion || 'unknown';
    },

    signup: function () {
      this.reset();
      this.msisdn = this.msisdn.replace(/\D/g, '');

      if (!this.termsCheckBox) {
        this.$toast.open({
          message: 'Please accept terms and conditions',
          type: 'error',
          position: 'top',
        });
        this.showTermsCheckBoxError = true;
        return;
      }

      if (this.msisdn.length < 9 || parseInt(this.msisdn) === 0) {
        this.$toast.open({
          message: 'Please enter a valid mobile number',
          type: 'error',
          position: 'top',
        });
        return;
      }

      if (this.password.length < 4) {
        this.$toast.open({
          message: 'Please enter a password of more than 4 characters',
          type: 'error',
          position: 'top',
        });
        return;
      }

      if (this.password !== this.password1) {
        this.$toast.open({
          message: 'Your passwords do not match',
          type: 'error',
          position: 'top',
        });
        return;
      }

      this.loading = 'loading';
      this.spinner = true;

      var vm = this;
      var path = "/signup";

      var browser = this.getBrowserInfo();
      var deviceInfo = this.getDeviceInfo();
      var engine = this.getEngineInfo();
      var versionInfo = this.getVersionInfo();

      var channelId = parseInt(this.getValue("channel_id")) || 0;

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var btag = this.getValue("btag");

      console.log("Register method triggered");
      console.log("Document Referrer:", document.referrer);
      console.log("Referral Code:", this.referralCode);

      axios.post(path, JSON.stringify({
        browser: browser,
        channel_id: channelId,
        device_info: deviceInfo,
        engine: engine,
        version_info: versionInfo,
        msisdn: parseInt(this.msisdn),
        password: this.password,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: document.referrer,
        referral_code: this.referralCode, // Using referralCode from state
        btag: btag
      }))
        .then(res => {
          vm.spinner = false;
          vm.loading = '';

          console.log('Response:', JSON.stringify(res, undefined, 2));

          var status = res.status;

          if (parseInt(status) === 202) {
            vm.setLogin();
            return;
          }
          this.$toast.open({
            message: 'Signup successful',
            type: 'success',
            position: 'top',
          });
          vm.setVerifyAccount();
        })
        .catch(err => {
          vm.spinner = false;
          vm.loading = '';

          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: 'error',
              position: 'top',
            });
          } else if (err.request) {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top',
            });
          } else {
            this.$toast.open({
              message: 'Check your network connection and try again',
              type: 'error',
              position: 'top',
            });
          }
        });
    },
    getResetCode: function () {
      this.reset();

      if (this.msisdn.length < 9) {
        this.setError("Invalid Mobile Number", "Please enter a valid mobile number");
        return;
      }

      this.loading = 'loading';

      var vm = this;
      var path = process.env.VUE_APP_URL_PASSWORD_RESET;

      var payload = {
        msisdn: parseInt(this.msisdn)
      };

      console.log("Request Payload:", payload);

      axios.patch(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          vm.loading = '';
          console.log(JSON.stringify(res));
          vm.setSuccess("Success", "Password reset code has been sent to your phone");
          // go to password reset page
          vm.setChangePassword();
        })
        .catch(err => {
          console.log(JSON.stringify(err));

          if (err.response) {
            vm.setError("Failed", err.response.data.message);
            console.log(JSON.stringify(err.response.data.message));
          } else if (err.request) {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log(JSON.stringify(err.request));
          } else {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log(JSON.stringify(err));
          }
        });
    },

    changePassword: function () {

      this.reset();

      if (this.password.length < 4) {

        this.setError("Invalid Password", "Please enter a password of more than 4 characters");
        return;

      }

      if (this.password !== this.password1) {

        this.setError("Password Mismatch", "Your passwords does not match");
        return;

      }

      if (this.code.length < 4) {

        this.setError("Invalid Code", "Please enter a valid verification code");
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_PASSWORD_CHANGE;

      var payload = {
        msisdn: parseInt(this.msisdn),
        password: this.password,
        code: parseInt(this.code)
      };

      console.log("Request Payload:", payload);

      axios.patch(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {

          console.log(JSON.stringify(res));

          vm.loading = '';
          vm.setSuccess("Password Changed", "Your password has been changed successfully,. Please login to continue");

          // go to login page
          vm.setLogin();

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            vm.setError("Failed", err.response.data.message)
            console.log(JSON.stringify(err.response.data.message));

          }
          else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          }
          else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })

    },
    verifyAccount: function () {
      this.reset();

      if (this.code.length < 4) {
        this.setError("Invalid Code", "Please enter a valid verification code");
        return;
      }

      this.loading = 'loading';

      var vm = this;
      var path = process.env.VUE_APP_URL_VERIFY;
      var currentDate = new Date();
      var login_tag = parseInt(currentDate.getTime() + '' + Math.floor(Math.random() * (9 * (Math.pow(10, 2)))) + (Math.pow(10, 2)));
      this.setValue("login_tag", login_tag);

      var utm_source = this.getValue("utm_source") || '';
      var utm_medium = this.getValue("utm_medium") || '';
      var utm_campaign = this.getValue("utm_campaign") || '';
      var referrer = this.getValue("referrer") || '';

      var payload = {
        msisdn: parseInt(this.msisdn),
        code: parseInt(this.code),
        login_tag: login_tag,
        source: utm_source,
        medium: utm_medium,
        campaign: utm_campaign,
        referrer: referrer
      };

      console.log("Request Payload:", payload);

      axios.post(path, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          vm.loading = '';
          var profile = res.data;
          var status = res.status;

          console.log("Response Status:", status);
          console.log("Response Data:", profile);

          vm.setProfile(profile);
          var auth = profile.auth;
          vm.setAuth(auth);

          vm.setSuccess("Congratulations!!", "Your account has been verified successfully");
          vm.EventBus.$emit('init:mqtt');

          if (parseInt(vm.placeBet) === 1) {
            vm.setValue("placeBet", 0);
            vm.EventBus.$emit('event:betslip:show');
          } else {
            vm.goHome();
          }
        })
        .catch(err => {
          vm.loading = '';
          console.log("Error:", err);

          if (err.response) {
            console.log("Error Response Data:", err.response.data);
            console.log("Error Response Status:", err.response.status);
            console.log("Error Response Headers:", err.response.headers);

            var errorMessage = err.response.data || "An error occurred";
            vm.setError("Failed", errorMessage);
          } else if (err.request) {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log("Error Request:", err.request);
          } else {
            vm.setError("Network Error", "Check your network connection and try again");
            console.log("Error Message:", err.message);
          }
        });
    },

    setSignup: function () {

      this.action_login = false;
      this.action_signup = true;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setLogin: function () {

      this.action_login = true;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setGetResetCode: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = true;
      this.action_verify_password = false;

    },
    setChangePassword: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = true;
      this.action_reset_password = false;
      this.action_verify_password = false;

    },
    setVerifyAccount: function () {

      this.action_login = false;
      this.action_signup = false;
      this.action_change_password = false;
      this.action_reset_password = false;
      this.action_verify_password = true;

    },
    showPassword: function () {
      if (document.getElementById('inputPass').type == "password") {
        document.getElementById('inputPass').type = "text"
        document.getElementById('inputPass').style.color = "white"
      } else {
        document.getElementById('inputPass').type = "password"
        document.getElementById('inputPass').style.color = "rgba(255,255,255,.75)"
      }
      // if(document.getElementById('signupPassword1').type == "password"){
      //   document.getElementById('signupPassword1').type = "text"
      // }else{
      //   document.getElementById('signupPassword1').type = "password"
      // }
      if (this.type === 'password') {
        this.type = 'text'
        this.btnText = '/img/icons/eye-slash.svg'
      } else {
        this.type = 'password'
        this.btnText = '/img/icons/eye.svg'
      }
    },

    showPasswordReset: function () {
      if (document.getElementById('signupPassword3').type == "password") {
        document.getElementById('signupPassword3').type = "text"
        document.getElementById('signupPassword3').style.color = "white"
      } else {
        document.getElementById('signupPassword3').type = "password"
        document.getElementById('signupPassword3').style.color = "rgba(255,255,255,.75)"
      }
      if (this.type2 === 'password') {
        this.type2 = 'text'
        this.btnText2 = '/img/other/eyeslash.svg'
      } else {
        this.type2 = 'password'
        this.btnText2 = '/img/other/eye.svg'
      }
    },
    showPasswordReset2: function () {
      if (document.getElementById('signupPassword4').type == "password") {
        document.getElementById('signupPassword4').type = "text"
        document.getElementById('signupPassword4').style.color = "white"
      } else {
        document.getElementById('signupPassword4').type = "password"
        document.getElementById('signupPassword4').style.color = "rgba(255,255,255,.75)"
      }
      if (this.type3 === 'password') {
        this.type3 = 'text'
        this.btnText3 = '/img/other/eyeslash.svg'
      } else {
        this.type3 = 'password'
        this.btnText3 = '/img/other/eye.svg'
      }
    }
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "login");
    this.referralCode = this.getValue("referral_code");
    this.placeBet = this.getValue("placeBet");
    var p = this.getProfile();
    if (!p) {
      console.log("");
    } else {
      this.$router.push({ name: 'home', params: {} });
      return;
    }

  }
}
</script>